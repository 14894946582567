import React, { Component, Fragment } from 'react';
import PItem from './item.js'
import './App.css';

import logo from './assets/img/Logo.svg'
import logo2 from './assets/img/Logo2.svg'
import img from './assets/img/img.png'
import img1 from './assets/img/img1.svg'


class Dashboard extends Component{
  state = {
     ssr : "https://www.splitsecondresearch.com/",
     intro : "<strong>Split Second Research Limited</strong> understands that your privacy is important to you and that you care about how it is used and shared online. We respect and value the privacy of everyone who completes our surveys from our survey platforms and therefore (subject to the limited exceptions in section 5, below) do not collect information about you unless you contact us (in which case, see section 4, below). Any information we do collect will only be used in ways that are useful to you and in a manner consistent with your rights and Our obligations under the law.",
     note : "Please read this Privacy Policy carefully and ensure that you understand it. Your acceptance of Our Privacy Policy is deemed to occur upon your first completion of our survey. If you do not accept and agree with this Privacy Policy, you must stop completing our survey immediately.",
     title : "PRIVACY POLICY",
     list : [
       {
          title : "1. Definitions and Interpretation",
          subtitle : "In this Policy the following terms shall have the following meanings:",
          content : `<table><tbody><tr><td><p><strong>&ldquo;Data&rdquo;</strong></p></td><td><p>means collectively all information that you submit to Split Second Research Limited via the Website. This definition shall, where applicable, incorporate the definitions provided in the Data Protection Act 1998;</p></td></tr><tr><td><p><strong>&ldquo;Split Second Research Limited&rdquo;</strong></p></td><td><p>means Split Second Research Limited of 30 New Street, Worcester, WR1 2DP;</p></td></tr><tr><td><p><strong>&ldquo;User&rdquo;</strong></p></td><td><p>means any third party that completes our surveys and is not employed by Split Second Research Limited and acting in the course of their employment; and</p></td></tr><tr><td><p><strong>&ldquo;Survey Platform&rdquo;</strong></p></td><td><p>means the website that you are currently using <a href="https://splitsecondsurveys.co.uk">https://splitsecondsurveys.co.uk</a> and any sub-domains of this site unless expressly excluded by their own terms and conditions.</p></td></tr></tbody></table>`
       },
       {
        title : "2. Information About Us",
        subtitle : "",
        content : `<ol><li>Our survey platform, <a href="https://splitsecondsurveys.co.uk">https://splitsecondsurveys.co.uk</a> is owned and operated by Split Second Research Limited, a limited company registered in England under 09455069 whose registered address is 30 New Street, Worcester, WR1 2DP.</li><li>We are regulated by the laws in England and Wales.</li></ol>`
       },      
       {
        title : "3. Scope – What Does This Policy Cover?",
        subtitle : "",
        content : `This Privacy Policy applies only to you completing our surveys. It does not extend to any websites that are linked to from Our Survey Platform (whether We provide those links or whether they are shared by other users). We have no control over how your data is collected, stored, or used by other websites and We advise you to check the privacy policies of any such websites before providing any data to them.`
       },
       {
        title : "4.	Data Collected",
        subtitle : "",
        content : `<ol><li>Subject to section 4.3, Our survey platforms do not collect any data from you unless specified at the beginning of the survey. We do not place cookies on your computer, nor do We use any other means of data collection.</li><li>From emails We may collect your name, your email address, and any other information which you choose to give us.</li><li>Our survey platforms automatically collect the following (non-personal) data:<ol><li>Your IP Address</li><li>Browser use</li><li>Type of device e.g., mobile or desktop.</li></ol></li></ol>`
       },    
       {
        title : "5.	How Do We Use Your Data?",
        subtitle : "",
        content : `<ol><li>All personal data is stored securely in accordance with the principles of the Data Protection Act 1998. For more details on security see section 6, below.</li><li>As noted above, we do not generally collect any data. Where We do (if you email Us, for example), We may use your data as follows:<ol><li>Responding to communications from you.</li><li>We will not share any of your data with any third parties for any purposes.</li></ol></li></ol>`
       },    
       {
        title : "6.	How and Where Do We Store Your Data?",
        subtitle : "",
        content : `<ol><li>We only keep your data for as long as We need to in order to use it as described above in section 5, and/or for as long as We have your permission to keep it.</li><li>Some or all of your data may be stored or transferred outside of the European Economic Area (&ldquo;the EEA&rdquo;) (The EEA consists of all EU member states, plus Norway, Iceland, and Liechtenstein). You are deemed to accept and agree to this when completing our surveys and submitting information to Us. If We do store or transfer data outside the EEA (this may be the case, for example, if Our email server is located in a country outside the EEA), We will take all reasonable steps to ensure that your data is treated as safely and securely as it would be within the EEA and under the Data Protection Act 1998.</li><li>Data security is of great importance to Us, and to protect your data We have taken suitable measures to safeguard and secure any data We hold about you (even if it is only your email address).</li><li>Steps We take to secure and protect your data include:<ul><li>Password protected computers; and</li><li>Password protected online storage.</li><li>Notwithstanding the security measures that We take, it is important to remember that the transmission of data via the internet may not be completely secure and that you are advised to take suitable precautions when transmitting to Us data via the internet.</li></ul></li></ol>`
       },    
       {
        title : "7.	How Can You Access Your Data?",
        subtitle : "",
        content : `<p>You have the legal right to ask for a copy of any of your personal data held by Us (where such data is held) on payment of a small fee which will not exceed &pound;10. Please contact Us for more details at <a href="mailto:info@splitsecondresearch.co.uk">info@splitsecondresearch.co.uk</a>.</p>`
       },    
       {
        title : "8.	Contacting Us",
        subtitle : "",
        content : `<p>If you have any questions about Our survey platforms or this Privacy Policy, please contact Us by email at <a href="mailto:info@splitsecondresearch.co.uk">info@splitsecondresearch.co.uk</a>, by telephone on +44 (0) 7878455944, or by post at 30 New Street, Worcester, WR1 2DP. Please ensure that your query is clear, particularly if it is a request for information about the data, we hold about you.</p>`
       },    
       {
        title : "9.	Changes to Our Privacy Policy",
        subtitle : "",
        content : `We may change this Privacy Policy as we may deem necessary from time to time, or as may be required by law. Any changes will be immediately posted on Our survey platform, and you will be deemed to have accepted the terms of the Privacy Policy on your first completion of Our surveys following the alterations. We recommend that you check this page regularly to keep up to date.`
       },    

    ]
  }


  render(){
    return (
    <Fragment>
      <div class="mainLogo"><a href={this.state.ssr} target="_blank" rel="noopener noreferrer"><img src={logo} alt=""/></a></div>
      <div className ="section">
          <div className="intro hcenter">
             <div className="body">
                <div className="content" dangerouslySetInnerHTML={{__html:this.state.intro}}></div>
                <div className="icon"><img src={img1} alt=""/></div>
             </div>
          </div>
      </div>
      <div className ="section bg4">
          <div className="note hcenter">
             <div className="body">
              <div className="content">{this.state.note}</div>
              <div className="icon"><img src={img} alt=""/></div>
            </div>
          </div>
     </div>
     <div className ="section bg1">
          <h1 className="mt2">{this.state.title}</h1>
          <div className="pList hcenter">
              {this.state.list.map((i,k)=><PItem {...i} key={k}></PItem>)}
          </div>
     </div>
     <div className ="section bg2">
          <div className="footer">
                <div className="body">
                      <div class="logo"><a href={this.state.ssr} target="_blank" rel="noopener noreferrer"><img src={logo2} alt=""/></a></div>
                      <div class="item">30 New Street, Worcester, WR1 2DP, United Kingdom</div>
                      <div class="item">Registered in England No. 09455069 VAT No. 258 728 756</div>
                      <div class="item">Data Protection Registration ZB175062</div>
                </div>
          </div>
     </div>
    </Fragment>);
  }

}



export default Dashboard;
