import React, { Component, Fragment } from 'react';


class PItem extends Component {
    render(){
        return (<Fragment>
            <div className="item">
                <div className="body">
                    <div className="name">{this.props.title}</div>
                    { (this.props.subtitle !== "") && <div className="sub">{this.props.subtitle}</div>}
                    <div className="cont" dangerouslySetInnerHTML={{__html:this.props.content}}></div>
                </div>
            </div>
        </Fragment>)
    }
}

export default PItem;